import { Navigate, Outlet } from "react-router";
import { useGlobalVar } from "../utils/GlobalContext";
import React, { useEffect, useState } from "react";
import { SessionTimeControl } from "../utils/SessionTimeControl";
import { useLogout } from "../../hooks/useLogout";
import { useAuth } from "react-oidc-context";
import { logoutUser } from "../../api/api";
import { useApiCall } from "../../hooks/useApiCall";
import { useNavigate } from "react-router-dom";

function PrivateRoute() {

  const auth = useAuth();
  const { isAuth, setLoggedOut, sessionId, expiredSession, setExpiredSession } = useGlobalVar();

  //let navigate = useNavigate();

  
  //useLogout(sessionId, auth, logoutCall,expiredSession);

  //setPathAfterLogin(useLocation().pathname || '');
  // return <Outlet />;
  return <><SessionTimeControl/> <div>{isAuth ? <Outlet /> : <Navigate to='/' />}</div></>;
}

export default PrivateRoute;
