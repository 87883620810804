import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;


export const subscribeApp = async (data, accessToken, userEmail) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/subscription/register`,
			data,
			{
				headers: {
					"Content-Type": "application/json",
					email: userEmail,
					Authorization: accessToken,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const modifySubscription = async (
	data,
	userEmail,
	accessToken
) => {
	try {
		const response = await axios.put(
			`${API_URL}/api/v1/subscription/edit`,
			data,
			{
				headers: {
					"Content-Type": "application/json",
					email: `${userEmail}`,
					Authorization: `${accessToken}`,
				},
			}
		);
		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const cancelSub = async (data, accessToken, userEmail) => {
	try {
		const response = await axios.delete(`${API_URL}/api/v1/subscription/cancel`, {
			headers: {
				Authorization: `${accessToken}`,
				Email: userEmail,
			},
			data: data,
		});

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const deleteSub = async (data, accessToken, userEmail) => {
	try {
		const response = await axios.delete(`${API_URL}/api/v1/subscription/delete`, {
			headers: {
				Authorization: `${accessToken}`,
				Email: userEmail,
				"Content-Type": "application/json",
			},
			data: data, // Aquí es donde especificamos el body de la petición DELETE
		});

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const getSubscriptionsByOrganization = async (
	nameOrganization,
	accessToken
) => {
	try {
		const response = await axios.get(
			`${API_URL}/api/v1/subscriptions-organization?nameOrganization=${nameOrganization}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const getModifyDetails = async (
	organization,
	nameApp,
	type,
	userEmail,
	accessToken
) => {
	try {
		const response = await axios.get(
			`${API_URL}/api/v1/subscriptions-by-admin-request?nameOrganization=${organization}&nameSubscriptions=${nameApp}&type=${type}`,
			{
				headers: {
					"Content-Type": "application/json",
					email: `${userEmail}`,
					Authorization: `${accessToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const getSubscriptionByEnviroment = async (
	organization,
	nameApp,
	environments,
	type,
	userEmail,
	accessToken
) => {
	try {
		const response = await axios.get(
			`${API_URL}/api/v1/subscriptions-by-environment?nameOrganization=${organization}&nameSubscriptions=${nameApp}&environment=${environments}&type=${type}`,
			{
				headers: {
					"Content-Type": "application/json",
					email: `${userEmail}`,
					Authorization: `${accessToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const getSubscriptionDelete = async (
	organization,
	nameApp,
	type,
	userEmail,
	accessToken
) => {
	try {
		const response = await axios.get(
			`${API_URL}/api/v1/subscriptions-by-environment?nameOrganization=${organization}&nameSubscriptions=${nameApp}&type=${type}`,
			{
				headers: {
					"Content-Type": "application/json",
					email: `${userEmail}`,
					Authorization: `${accessToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const updateSuscription = async (
	suscriptionData,
	userEmail,
	accessToken
) => {
	try {
		const response = await axios.put(
			`${API_URL}/api/v1/upadate-subscription-by-request-api`,
			suscriptionData,
			{
				headers: {
					"Content-Type": "application/json",
					email: `${userEmail}`,
					Authorization: `${accessToken}`,
				},
			}
		);
		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const deleteSubscription = async (
	subscriptionData,
	userEmail,
	accessToken
) => {
	try {
		const response = await axios.put(
			`${API_URL}/api/v1/update-subscription-by-delete-api`,
			subscriptionData,  // Datos que se envían en el cuerpo de la petición
			{
				headers: {
					"Content-Type": "application/json",
					email: userEmail,
					Authorization: `${accessToken}`, // Asegúrate de que el formato del token sea correcto
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const supportByEmail = async (userEmail, id, accessToken) => {
	try {
		const response = await axios.get(`${API_URL}/api/v1/support-by-email`, {
			headers: {
				email: userEmail,
				id: id,
				Authorization: `${accessToken}`,
			},
		});

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const updateSupport = async (supportData, userEmail, accessToken) => {
	try {
		const response = await axios.put(`${API_URL}/api/v1/upadate-sopport-by-request-api`, 
		supportData,
		{
			headers: {
				email: userEmail,
				Authorization: `${accessToken}`,
			},
		});

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const aproveNewCustomer = async (idOrganization, idSolicitud, accessToken, userEmail) => {
	try {
		const response = await axios.patch(
			`${API_URL}/api/v1/plan/upgrade/${idOrganization}/${idSolicitud}/approve`,
			{}, 
			{
				headers: {
					Authorization: `${accessToken}`, 
					email: userEmail,
				},
			}
		);

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const rejectNewCustomer = async (idOrganization, idSolicitud, accessToken, userEmail, message) => {
	try {
		const response = await axios.patch(
			`${API_URL}/api/v1/plan/upgrade/${idOrganization}/${idSolicitud}/reject?message=${message}`,
			{}, 
			{
				headers: {
					Authorization: `${accessToken}`, 
					email: userEmail,
				},
			}
		);

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
	
};


