import React from "react";

const CookiePolicy = () => {
	return (
		<section className="info-legal">
			<div className="div-block-7 legalintro">
				    <header>
        <h1>Payments Hub Customer Developer Portal Cookie Notice</h1>
        <p className="text-legal"><strong>Version 2:</strong> Only Tech Cookies</p>
        <p className="text-legal"><strong>Date:</strong> 20/01/2025</p>
        <p className="text-legal"><strong>Author:</strong> PH DPO</p>
    </header>

    <main>
        <section>
            <h2 className="title-int-legal">COOKIE POLICY</h2>
            <p className="text-legal">PagoNxt Payments Service S.L. (also known as PagoNxt or WE) is the publisher of this website. We want to inform you about our cookie policy so that you know what cookies we use and if you decide so, you can give your consent to said cookies.</p>
            <p className="text-legal">This cookie policy (the "Cookie Policy") is supplemented by the Privacy Notice for this Website, which can be found at the footer of the Website.</p>
        </section>

        <section>
            <h2 className="title-int-legal">1. What are cookies?</h2>
            <p className="text-legal">A cookie is a small text file that is downloaded onto ‘terminal equipment’ (e.g., a computer or smartphone) when the user accesses a website. It allows the website to recognise that user’s device and store some information about the user’s preferences or past actions. These technologies may be used for a wide range of purposes, such as recognising you as a user, obtaining information on your browsing habits or behaviour on the Site or even offering you personalised advertising based on the foregoing. The specific use we make of these technologies is detailed further on.</p>
            <p className="text-legal">For more information on how websites and apps use cookies, you can visit <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>.</p>
        </section>

        <section>
            <h2 className="title-int-legal">2. Data Protection</h2>
            <p className="text-legal">Under certain circumstances, the use of cookies may involve the processing of the personal data of the users of these devices when they visit the site. This data processing, except in the cases where it is necessary for browsing the site, will be subject to the condition that the users have given their consent after having been provided with clear and comprehensive information on their use regarding the purposes of the data processing, with regard to the protection of personal data.</p>
            <p className="text-legal">For further information, you can contact PagoNxt’s data protection officer by email at <a href="mailto:privacy.payments@pagonxt.com">privacy.payments@pagonxt.com</a> or <a href="mailto:privacy.trade@pagonxt.com">privacy.trade@pagonxt.com</a>, or by checking the Privacy Notice that can be found at the footer of this website.</p>
        </section>

        <section>
            <h2 className="title-int-legal">3. Authorization to use cookies</h2>
            <p className="text-legal">We only use technical cookies that are strictly necessary for the proper functioning of the site. According to the applicable laws, the use of these cookies is exempt from the consent requirement.</p>
        </section>

        <section>
            <h2 className="title-int-legal">4. What types of cookies do we use?</h2>
            <p className="text-legal">While you browse the site you may find technical first party cookies embedded directly by the publisher. They may also be session or persistent cookies.</p>
            <p className="text-legal">To help you understand the types of cookies we use, please find below a detailed explanation of them:</p>
            <ul>
                <li><span class="highlight">Session cookies:</span> these are designed to gather and store data whilst the user has access to a Site. These cookies are not stored on the user's device when the session expires, or the browser is closed.</li>
                <li><span class="highlight">Persistent cookies:</span> these are cookies in which the data continues to be stored on the user's device when they leave the Site and may be accessed and processed during a period defined by the owner of the cookie, which could be a few minutes or several years.</li>
                <li><span class="highlight">Technical cookies:</span> These allow the user to browse the Site and use the different options or services available, including those used to permit the management and operation of the Site and enable its functions and services, such as, for example, controlling data traffic and communication, identifying the session, accessing restricted access areas, remembering the elements forming an order, etc. Also included in this category, by virtue of their technical nature and provided that they are not used for other non-exempted purposes, are those cookies that allow advertising spaces to be managed in the most efficient manner, as they are an element more closely associated with the design or layout of the service offered to the user included in the website, application or platform, based on criteria such as the content edited. They do not collect information about users for other purposes, such as the personalisation of this advertising content or other content. Specifically, we use the following technical cookies:</li>
                        </ul>
                <table style={{width: '100%', borderCollapse: 'collapse', border: '2px solid #e0e0e0'}}>
					<tbody>
                        <tr>
                            <td style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>COOKIE NAME</td>
                            <td style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4'}}>OWNER</td>
                            <td style={{ padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4' }}>PURPOSE</td>
                            <td style={{ padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4' }}>DURATION</td>
                        </tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>api.developer.pagonxtpayments.com</td>
                        <td style={{ padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff' }}>PagoNxt Payments Services, S.L. </td>
                        <td style={{ padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff' }}>accessToken</td>
                        <td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>Session</td>
                    </tr>
                    <tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>developer.pagonxtpayments.com</td>
                        <td style={{ padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff' }}>PagoNxt Payments Services, S.L. </td>
                        <td style={{ padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff' }}>accessToken</td>
                        <td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>Session</td>
                                </tr>
                                                    <tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>pagonxtpayments.com</td>
                        <td style={{ padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff' }}>PagoNxt Payments Services, S.L. </td>
                        <td style={{ padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff' }}>Hosting</td>
                        <td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>Session</td>
					</tr>
					
					</tbody>
				</table>

        </section>

        <section>
            <h2 className="title-int-legal">5. Update to the Cookies Policy and contact details</h2>
            <p className="text-legal">This cookie policy might change, depending on the cookies used.  If we start using new types of cookies that need you to agree to them, we will tell you and ask for your agreement before we insert them on your browser. </p>
            <p className="text-legal">Please check this policy regularly when you visit our website so that you know how and why we use cookies and any changes in the type of data collected. </p>
            <p className="text-legal">If you have any questions about this policy, you can contact PagoNxt’s DPO at the following address: <a href="mailto:privacy.payments@pagonxt.com">privacy.payments@pagonxt.com</a> or <a href="mailto:privacy.trade@pagonxt.com">privacy.trade@pagonxt.com</a> with "Cookies Policy" in the subject line.</p>
            <p className="text-legal"><strong>Last update:</strong> January 17th, 2025</p>
        </section>
    </main>


			</div>
		</section>

	);
};

export default CookiePolicy;
