import cognito from "../cognito/cognito";
import { useApiCall } from "./useApiCall";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../api/api";
import { useGlobalVar } from "../components/utils/GlobalContext";
import { useAuth } from "react-oidc-context";

const useLogOut = () => {
    const auth = useAuth();
    const { setLoggedOut, sessionId } = useGlobalVar();
    let navigate = useNavigate();
    const [, , logoutCall] = useApiCall((data) => logoutUser(data), {
        executeImmediately: false,
        onSuccess: () => {
            setLoggedOut();
            navigate("/logout");
        },
    });

    const logOut = function () {        
        const user = cognito.getCurrentUser();
            if (user) {
                user.signOut();
            } else {
                auth.signoutSilent();
            }
            window.sessionStorage.clear();
            window.localStorage.clear();
            logoutCall(sessionId);
    }
    return {logOut};
}


export default useLogOut;