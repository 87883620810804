import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../assests/logo.svg";
import { Outlet } from "react-router";
import { RequestContext } from "../context/AuditContext";
import LinkComponent from "../atoms/link-component/LinkComponent";

import {
	REQUEST,
	ORGANIZATIONS,
	USERS,
	AUDIT,
	SETTINGS,
	REQUESTS_MANAGEMENT
} from "../utils/Routes";
import MenuLoggedComponent from "../molecules/menu-logged-component/MenuLoggedComponentComponent";

function AdminFullScreenLayout() {
	const [activeMenuItem, setActiveMenuItem] = useState("");
	const requestLength = useContext(RequestContext);
	const location = useLocation();

	useEffect(() => {
		// Determine active menu item based on current path
		const path = location.pathname;
		if (path === REQUEST) setActiveMenuItem("Request");
		else if (path === ORGANIZATIONS) setActiveMenuItem("Organizations");
		else if (path === USERS) setActiveMenuItem("Users");
		else if (path === AUDIT) setActiveMenuItem("Audit");
		else if (path === SETTINGS) setActiveMenuItem("Settings");
	}, [location.pathname]);

	const handleMenuClick = (menuItem) => {
		setActiveMenuItem(menuItem);
	};

	return (
		<>
			<header className="header--logged">
				<div className="header--logged__container">
					<div className="header--logged__logo">
						<a href={REQUESTS_MANAGEMENT}>
							<img src={logo} alt="Logo" />
						</a>
					</div>
					<div className="header--logged__buttons">
						<div className="header--logged__buttons__menu">
							<LinkComponent
								text={"Request"}
								url={REQUEST}
								counter={requestLength}
								className={
									activeMenuItem === "Request" ? "active" : ""
								}
								onClick={() => handleMenuClick("Request")}
							/>
							<LinkComponent
								text={"Organizations"}
								icon={"icon-organizations"}
								url={ORGANIZATIONS}
								className={
									activeMenuItem === "Organizations"
										? "active"
										: ""
								}
								onClick={() => handleMenuClick("Organizations")}
							/>
							<LinkComponent
								text={"Users"}
								icon={"icon-users"}
								url={USERS}
								className={
									activeMenuItem === "Users" ? "active" : ""
								}
								onClick={() => handleMenuClick("Users")}
							/>
							<LinkComponent
								text={"Audit Log"}
								icon={"icon-audit"}
								url={AUDIT}
								className={
									activeMenuItem === "Audit" ? "active" : ""
								}
								onClick={() => handleMenuClick("Audit")}
							/>
							<LinkComponent
								text={"Settings"}
								icon={"icon-settings"}
								url={SETTINGS}
								className={
									activeMenuItem === "Settings"
										? "active"
										: ""
								}
								onClick={() => handleMenuClick("Settings")}
							/>
						</div>
						<div className="header--logged__buttons__menu-logged">
							<MenuLoggedComponent
								name={"Name Lastname"}
								email={"name.lastname@domain.com"}
							/>
						</div>
					</div>
				</div>
			</header>

			<main>
				<div className="body-container">
					<div className="wrapper">
						<Outlet />
					</div>
				</div>
			</main>

      <footer>
        <div className="footer">
          <div className="footer__bottom">
              <p className="footer__bottom__disclaimer">
                DISCLAIMER - PagoNxt Payments Services, S.L. is a Spanish company domiciled in Avda. de Cantabria s/n, Ciudad Grupo Santander, 28660, Boadilla del Monte (Madrid) and with tax identification number B-87959326. PagoNxt Payments Services, S.L. is registered in the Corporate Registry of Madrid, volume 36500, sheet 204, page M-655666.<br /> <br />

                © 2024 PagoNxt S.L. PagoNxt is a registered trademark. All rights reserved.
              </p>
          </div>
        </div>
      </footer>
		</>
	);
}

export default AdminFullScreenLayout;
