import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalVar } from "../../../utils/GlobalContext";

import InputComponent from "../../../atoms/input-component/InputComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import ModalComponent from "../../../molecules/modal-component/ModalComponent";
import RejectRequest from "../../../organisms/reject-request/RejectRequest";
import ApiSubscriptionStateComponentAdmin from "../../../atoms/api-subscription-state-component-admin/ApiSubscriptionStateComponentAdmin";

import { error as showError, success as showSuccess } from "../../../utils/NotificationsContainer";

import { REQUESTS_MANAGEMENT } from "../../../utils/Routes";

import { getModifyDetails, updateSuscription } from "../../../../services/ApiSubscriptions";


const ModifyRequestDetails = ({ currentData }) => {
	const { accessToken, userEmail, userData } = useGlobalVar();

	const navigate = useNavigate();

	const [requestResponse, setRequestResponse] = useState({});

	const [showModal, setShowModal] = useState(false);
	const [rejectMessage, setRejectMessage] = useState('');

	const closeModal = () => setShowModal(false);
	const openModal = () => setShowModal(true);

	const handleAprobar = async (e) => {
		e.preventDefault();

		// Combina los datos de requestResponse con confidentialData
		const updatedData = {
			...requestResponse,
			type: "MODIFY",
			done: true,
			rejected: false
		};

		// CONFIGURACIÓN DEL HEADER
		try {
			await updateSuscription(updatedData, userData.email, accessToken);
			showSuccess('Updated request');
			navigate(REQUESTS_MANAGEMENT);
		} catch (error) {
			showError('There was a problem, please try again');
			console.error(error);
		}
	};

	const handleReject = async (e) => {
		e.preventDefault();

		const updatedData = {
			...requestResponse,
			type: "MODIFY",
			rejected: true,
			done: false
		};

		updatedData.mensaje = rejectMessage;

		try {
			await updateSuscription(updatedData, userData.email, accessToken);
			showSuccess('Updated request');
			navigate(REQUESTS_MANAGEMENT);
		} catch (error) {
			showError('There was a problem, please try again');
			console.error(error);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				
				const response = await getModifyDetails(currentData.organization, currentData.nameApp, currentData.type, currentData?.email, accessToken);



				setRequestResponse(response);
			} catch (error) {
				// setError(error);
			} finally {
				// setLoading(false);
			}
		};

		fetchData();
	}, [currentData, accessToken]);

	return (
		<div className="subscribe-request-details">
			<div>
				<h2 className="mg mg--b-4">
					Request for {requestResponse?.environment} environment
				</h2>
				<div className="subscribe-request-details__apis">
					<div className="subscribe-request-details__apis__title">
						APIs subscription
					</div>
					<div className="subscribe-request-details__apis__content">
						{/* requestResponse?.subscribeAPI?.apiIso === 'PENDING' ?  */}
						<div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
							<div className="input-content__checkboxes__checkbox__text">
								<div className="input-content__checkboxes__checkbox__text__title">
									<span>
									API ISO20022
									</span>
								</div>
								<div className="input-content__checkboxes__checkbox__text__description">
									<span>
									Brief description of the API utility
									</span>
								</div>
								<div className="input-content__checkboxes__checkbox__subscription">
									<ApiSubscriptionStateComponentAdmin state={requestResponse?.subscribeAPI?.apiIso}/>
								</div>
							</div>
						</div>  

						{/* requestResponse?.subscribeAPI?.apiRTran === 'PENDING' ?  */}
						
						{/* <div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
							<div className="input-content__checkboxes__checkbox__text">
								<div className="input-content__checkboxes__checkbox__text__title">
									<span>
									API R-Transactions
									</span>
								</div>
								<div className="input-content__checkboxes__checkbox__text__description">
									<span>
									Brief description of the API utility
									</span>
								</div>
								<div className="input-content__checkboxes__checkbox__subscription">
									<ApiSubscriptionStateComponentAdmin state={requestResponse?.subscribeAPI?.apiRTran}/>
								</div>
							</div>
						</div>   */}

						{/* requestResponse?.subscribeAPI?.apiCustomer === 'PENDING' ?  */}
						{/* <div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
							<div className="input-content__checkboxes__checkbox__text">
								<div className="input-content__checkboxes__checkbox__text__title">
									<span>
									API Customer Portal
									</span>
								</div>
								<div className="input-content__checkboxes__checkbox__text__description">
									<span>
									Brief description of the API utility
									</span>
								</div>
								<div className="input-content__checkboxes__checkbox__subscription">
									<ApiSubscriptionStateComponentAdmin state={requestResponse?.subscribeAPI?.apiCustomer}/>
								</div>
							</div>
						</div>   */}
					</div>
				</div>

				{requestResponse?.publicKey ? (
					<InputComponent
						type="textarea"
						name="publicKey"
						label="Public Key"
						value={`-----BEGIN PUBLIC KEY-----\n${requestResponse?.publicKey}\n-----END PUBLIC KEY-----`}
						disabled={true}
						className={"input--extralarge"}
					/>
				) : null}
				{requestResponse?.keyId ? (
					<InputComponent
						type="text"
						name="keyID"
						label="Key ID"
						value={requestResponse?.keyId}
						disabled={true}
						className={"input--medium"}
					/>
				) : null}

				{requestResponse?.insertIssuer ? (
					<InputComponent
						type="text"
						name="issuer"
						label="Issuer"
						value={requestResponse?.insertIssuer}
						disabled={true}
						className={"input--medium"}
					/>
				) : null}
			</div>

			<DividerComponent />

			{requestResponse.environment === "LIVE" ? (
				<>
					<div>
						<h3 className="mg mg--b-4">Notifier endpoint</h3>
						{/* NOTIFICATIONS TYPE */}
						{requestResponse?.webhoot ? (
							<InputComponent
								type="text"
								name="notificationsType"
								label="Notifications type"
								value={"Webhook"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.statusReport ? (
							<InputComponent
								type="text"
								name="notificationsType"
								label="Notifications type"
								value={"Status report hub messages"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}

						{/* NOTIFIER SECURITY */}
						{requestResponse?.oauthSecurity ? (
							<InputComponent
								type="text"
								name="notifierSecurity"
								label="Notifier security"
								value={"OAuth 2.0"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.basicSecurity ? (
							<InputComponent
								type="text"
								name="notifierSecurity"
								label="Notifier security"
								value={"HTTP Basic"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{/* {requestResponse?.notSecurity ? (
							<InputComponent
								type="text"
								name="notifierSecurity"
								label="Notifier security"
								value={"Without security"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null} */}

						{/* OAUTH SECURITY PARAMS */}
						{requestResponse?.oauthParam?.notificationUrl ? (
							<InputComponent
								type="text"
								name="funtionalEndpoint"
								label="Funtional endpoint"
								value={
									requestResponse?.oauthParam?.notificationUrl
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.oauthParam?.clientID ? (
							<InputComponent
								type="text"
								name="webhook"
								label="Webhook Client ID"
								value={requestResponse?.oauthParam?.clientID}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.oauthParam?.clientSecret ? (
							<InputComponent
								type="text"
								name="clientSecret"
								label="Client Secret"
								value={
									requestResponse?.oauthParam?.clientSecret
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.oauthParam?.tokenUrl ? (
							<InputComponent
								type="text"
								name="tokenURI"
								label="Token URI"
								value={requestResponse?.oauthParam?.tokenUrl}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}

						{/* BASIC SECURITY PARAMS */}
						{requestResponse?.basicSecurityParam
							?.notificationUrl ? (
							<InputComponent
								type="text"
								name="funtionalEndpoint"
								label="Funtional endpoint"
								value={
									requestResponse?.basicSecurityParam
										?.notificationUrl
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.basicSecurityParam?.user ? (
							<InputComponent
								type="text"
								name="user"
								label="User"
								value={
									requestResponse?.basicSecurityParam?.user
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.basicSecurityParam?.pass ? (
							<InputComponent
								type="text"
								name="password"
								label="Password"
								value={
									requestResponse?.basicSecurityParam?.pass
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}

						{/* WITHOUT SECURITY PARAMS */}
						{/* {requestResponse?.notSecurityParam?.tokenUrl ? (
							<InputComponent
								type="text"
								name="tokenURI"
								label="Token URI"
								value={
									requestResponse?.notSecurityParam?.tokenUrl
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null} */}
					</div>
					<DividerComponent />
				</>
			) : null}

			<div>
				<h3 className="mg mg--b-4">
					Confidential information from PagoNxt
				</h3>
				<div className="subscribe-request-details__information-inputs">
					<div className="input-content">
						<div className="input-content__title">
							<span>Client ID</span>
						</div>
						<label>
							<input
								type="text"
								name="clientAppId"
								value={requestResponse?.clientAppId || ""}
								placeholder={""}
								autoComplete="off"
								disabled
							/>
						</label>
					</div>
					<div className="input-content">
						<div className="input-content__title">
							<span>Secret</span>
						</div>
						<label>
							<input
								type="text"
								name="secret"
								value={requestResponse?.secret || ""}
								placeholder={""}
								autoComplete="off"
								disabled
							/>
						</label>
					</div>
				</div>
			</div>

			<DividerComponent />

			<div className="pair-buttons">
				<ButtonComponent
					text={"Cancel"}
					onClick={openModal}
					color={"button-component--reject"}
				/>
				<ButtonComponent
					text={"Done"}
					onClick={handleAprobar}
					color={"button-component"}
				/>
			</div>

			<ModalComponent show={showModal} onClose={closeModal} size={'lg'}>
				<RejectRequest onMessageChange={setRejectMessage} rejectRequest={handleReject} onClose={closeModal}/>
			</ModalComponent>
		</div>
	);
};

export default ModifyRequestDetails;
