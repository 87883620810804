import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { updatePassword } from "../../../services/ApiAuth";
import { useNavigate } from "react-router-dom";

import ButtonComponent from "../../atoms/button-component/ButtonComponent";
import InputComponent from "../../atoms/input-component/InputComponent";

import { HOME } from "../../utils/Routes";

const ResetPassword = () => {
	const location = useLocation();

	const navigate = useNavigate();

	const queryParams = new URLSearchParams(location.search);

	const code = queryParams.get("code");
	const user = queryParams.get("user");

	const [userPassword, setUserPassword] = useState({
		email: user,
		password: "",
		confirmPassword: "",
		confirmationCode: code,
	});

	const handleChange = (name, value) => {
		setUserPassword((prevUserPassword) => ({
			...prevUserPassword,
			[name]: value,
		}));
	};

	// Validaciones de la contraseña
	const isLengthValid = userPassword.password.length >= 8;
	const hasUpperCase = /[A-Z]/.test(userPassword.password);
	const hasLowerCase = /[a-z]/.test(userPassword.password);
	const hasNumberOrSpecialChar = /[0-9!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~¿¡€¬]/.test(userPassword.password);
	const passwordsMatch =
		userPassword.password === userPassword.confirmPassword;

	const isFormValid = () => {
		return (
			isLengthValid &&
			hasUpperCase &&
			hasLowerCase &&
			hasNumberOrSpecialChar &&
			passwordsMatch
		);
	};

	// Determinar el color del texto según el estado
	const getValidationColor = (isValid) => {
		return userPassword.password.length === 0
			? "#52627A"
			: isValid
			? "#37A685"
			: "#C41C1C";
	};

	// Determinar el símbolo a mostrar según el estado
	const getValidationSymbol = (isValid) => {
		return userPassword.password.length === 0 ? "" : isValid ? "✔️" : "X";
	};

	const handleSubmit = async (e) => {
		if (e) e.preventDefault();
		try {
			const { confirmPassword, ...dataToSend } = userPassword; // Omitir confirmPassword en la solicitud
			const result = await updatePassword(dataToSend);
			navigate(HOME);
			return result;
		} catch (error) {
			console.error("Error:", error);
		}
	};

	return (
		<>
			<div className="password-form">
				<form className="reset_new_password">
					<h2 className="p p--t-4 p--b-4 login-form__addpassword__title" style={{ textAlign: "center" }}>
						New password
					</h2>
					<div>

						<InputComponent
							type="password"
							name="password"
							value={userPassword.password}
							label="Password"
							placeholder="Insert your new password"
							onChange={handleChange}
						/>

						<InputComponent
							n
							type="password"
							name="confirmPassword"
							value={userPassword.confirmPassword}
							label="Confirm Password"
							placeholder="Repeat your new password"
							onChange={handleChange}
						/>

						<div className="p p--b-4">
							<ul style={{ listStyleType: "none", padding: 0 }}>
								<li
									style={{
										color: getValidationColor(isLengthValid),
									}}
								>
									{getValidationSymbol(isLengthValid)} At least 8
									characters
								</li>
								<li
									style={{
										color: getValidationColor(hasUpperCase),
									}}
								>
									{getValidationSymbol(hasUpperCase)} At least one
									uppercase letter
								</li>
								<li
									style={{
										color: getValidationColor(hasLowerCase),
									}}
								>
									{getValidationSymbol(hasLowerCase)} At least one
									lowercase letter
								</li>
								<li
									style={{
										color: getValidationColor(
											hasNumberOrSpecialChar
										),
									}}
								>
									{getValidationSymbol(hasNumberOrSpecialChar)} At
									least one number or special character (excluding -, [, ], and ^).
								</li>
							</ul>
						</div>
						{!passwordsMatch &&
							userPassword.confirmPassword.length > 0 && (
								<div
									className="p p--b-4"
									style={{ color: "red", marginTop: "10px" }}
								>
									❌ Passwords do not match
								</div>
							)}
					</div>

					<ButtonComponent
						text={"Save your password"}
						onClick={handleSubmit}
						disabled={!isFormValid()}
					/>
				</form>
			</div>
		</>
	);
};

export default ResetPassword;
