import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalVar } from "../../../utils/GlobalContext";

import InputComponent from "../../../atoms/input-component/InputComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import ModalComponent from "../../../molecules/modal-component/ModalComponent";
import RejectRequest from "../../../organisms/reject-request/RejectRequest";

import { getOrganizationsTypes } from "../../../../services/ApiOrganization";

import { getCustomerDetails, downloadCustomerFiles } from "../../../../services/ApiUsers";

import {
	aproveNewCustomer,
	rejectNewCustomer,
} from "../../../../services/ApiSubscriptions";

import {
	error as showError,
	success as showSuccess,
} from "../../../utils/NotificationsContainer";

import { REQUESTS_MANAGEMENT } from "../../../utils/Routes";


const NewCostumerRequestDetails = ({ currentData, updatePhone }) => {
	const { accessToken, userEmail, userData } = useGlobalVar();

	const navigate = useNavigate();

	const [newCustomerAPIResponse, setNewCustomerAPIResponse] = useState(null);
	const [organizationDetails, setOrganizationDetails] = useState(null);
	const [downloadableFiles, setDownloadableFiles] = useState([]);

	const isCostumer = true;

	const [showModal, setShowModal] = useState(false);
	const [rejectMessage, setRejectMessage] = useState("");

	const closeModal = () => setShowModal(false);
	const openModal = () => setShowModal(true);

	// Función para decodificar y descargar archivos
	const downloadFile = (fileContent, fileName) => {
		// Decodificar la cadena Base64
		const byteCharacters = atob(fileContent);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], { type: "application/pdf" });

		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
	};

	const handleAprobar = async (e) => {
		e.preventDefault();

		try {
			await aproveNewCustomer(
				newCustomerAPIResponse.organizationCode,
				currentData.idNewCustomer,
				accessToken,
				userData.email
			);
			showSuccess("Updated request");
			navigate(REQUESTS_MANAGEMENT);
		} catch (error) {
			showError("There was a problem, please try again");
			console.error(error);
		}
	};

	const handleReject = async (e) => {
		e.preventDefault();

		try {
			await rejectNewCustomer(
				newCustomerAPIResponse.organizationCode,
				currentData.idNewCustomer,
				accessToken,
				userData.email,
				rejectMessage
			);
			showSuccess("Updated request");
			navigate(REQUESTS_MANAGEMENT);
		} catch (error) {
			showError("There was a problem, please try again");
			console.error(error);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				// Llamar a la función para obtener los detalles del cliente
				const customerDetails = await getCustomerDetails(
					currentData?.idNewCustomer,
					currentData?.email,
					accessToken
				);
	
				const organizationTypes = await getOrganizationsTypes(accessToken);
	
				// Buscar el objeto con el codOrganization específico
				const organizationType = organizationTypes.find(
					(org) => org.codOrganization === customerDetails.organizationCode
				);
	
				const filesUploaded = customerDetails.filesUploaded;
	
				// Llamar a la función para descargar los archivos
				const downloadedFiles = await downloadCustomerFiles(
					customerDetails.organizationCode,
					filesUploaded,
					currentData?.email,
					accessToken
				);
	
				// Actualiza el estado con los archivos descargados
				setDownloadableFiles(downloadedFiles);
	
				setNewCustomerAPIResponse(customerDetails);
				setOrganizationDetails(organizationType.description);
				updatePhone(customerDetails.phone);
			} catch (error) {
				console.error("Error al descargar el archivo:", error);
			}
		};
	
		fetchData();
	}, [currentData, accessToken, updatePhone]);

	return (
		<div className="subscribe-request-details">
			<div className="mg mg--b-4">
				<h2>Contact information</h2>
				<div className="request-management__actions mg mg--t-2">
					<InputComponent
						type="text"
						name="user"
						label="User"
						value={newCustomerAPIResponse?.firstName || ""}
						disabled={true}
						className={"input--medium"}
					/>
					<InputComponent
						type="text"
						name="email"
						label="Email"
						value={newCustomerAPIResponse?.email || ""}
						disabled={true}
						className={"input--medium"}
					/>
					<InputComponent
						type="tel"
						name="phone"
						label="Phone"
						value={newCustomerAPIResponse?.phone || ""}
						disabled={true}
						className={"input--medium"}
					/>
				</div>
			</div>
			<div>
				<h2>information</h2>
				<div className="request-management__actions mg mg--t-2 mg--b-6">
					<InputComponent
						type="text"
						name="organization"
						label="Organization"
						value={newCustomerAPIResponse?.companyName || ""}
						disabled={true}
						className={"input--medium"}
					/>
					<InputComponent
						type="text"
						name="nif"
						label="Fiscal identity number (NIF)"
						value={newCustomerAPIResponse?.nif || ""}
						disabled={true}
						className={"input--medium"}
					/>
					<InputComponent
						type="text"
						name="codigoOrganization"
						label="Type of organization"
						value={organizationDetails || ""}
						disabled={true}
						className={"input--medium"}
					/>
					<InputComponent
						type="text"
						name="country"
						label="Country"
						value={newCustomerAPIResponse?.country || ""}
						disabled={true}
						className={"input--medium"}
					/>
				</div>
				{newCustomerAPIResponse?.alreadyCustomer && (
					<InputComponent
						type="checkbox"
						name="isCostumer"
						checked={isCostumer}
						label="This contact is already a customer"
						value={"Spain"}
						className={"input--medium"}
						onChange={() => {}}
					/>
				)}
			</div>
			<DividerComponent />
			<div>
				<h2>Attached files</h2>
				<div className=" request-management__filters mg mg--t-2 mg--b-4">
					{downloadableFiles.length > 0 ? (
						<ul>
							{downloadableFiles.map((file, index) => (
								<li key={index}>
									<button
										onClick={() =>
											downloadFile(
												file.fileContent,
												file.fileName
											)
										}
									>
										{file.fileName}
									</button>
								</li>
							))}
						</ul>
					) : (
						<p>No hay archivos disponibles para descargar.</p>
					)}
				</div>
			</div>
			<DividerComponent />
			<div className="pair-buttons mg mg--t-4">
				<ButtonComponent
					text={"Cancel"}
					onClick={openModal}
					color={"button-component--reject"}
				/>
				<ButtonComponent
					text={"Done"}
					onClick={handleAprobar}
					color={"button-component"}
				/>
			</div>

			<ModalComponent show={showModal} onClose={closeModal} size={"lg"}>
				<RejectRequest
					onMessageChange={setRejectMessage}
					rejectRequest={handleReject}
					onClose={closeModal}
				/>
			</ModalComponent>
		</div>
	);
};

export default NewCostumerRequestDetails;
