import { useEffect } from "react";
import useLogOut from "../../hooks/useLogout"

export const SessionTimeControl = () => {
    
    const {logOut} = useLogOut();
    const timeExpiration = process.env.REACT_APP_TIME_EXPIRED
    const checkForInactivity = () => {

        const expireTime = localStorage.getItem("expireTime");

        if (expireTime < Date.now()) {
            console.log("cierra sesión");
            logOut();
            //isAuth(false);
        }

    }

    const updateExpireTime = () => {

        const expireTime = Date.now() + Number(timeExpiration);

        localStorage.setItem("expireTime", expireTime);

    }

    useEffect(() => {

        const interval = setInterval(() => {
            checkForInactivity();
        }, 5000);

        return () => clearInterval(interval);

    }, []);

    useEffect(() => {

        updateExpireTime();

        window.addEventListener("click", updateExpireTime);
        window.addEventListener("keypress", updateExpireTime);
        window.addEventListener("scroll", updateExpireTime);
        window.addEventListener("mousemove", updateExpireTime);

        return () => {
            window.removeEventListener("click", updateExpireTime);
            window.removeEventListener("keypress", updateExpireTime);
            window.removeEventListener("scroll", updateExpireTime);
            window.removeEventListener("mousemove", updateExpireTime);
        }

    }, []);

    return null;
}