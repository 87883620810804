import React, { useEffect, useState, useCallback } from "react";
import { useGlobalVar } from "../../utils/GlobalContext";

import { useNavigate } from "react-router-dom";

import {
  error as showError,
  success as showSuccess,
} from "../../utils/NotificationsContainer";

import DividerComponent from "../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../atoms/button-component/ButtonComponent";
import LinkComponent from "../../atoms/link-component/LinkComponent";
import ModalComponent from "../../molecules/modal-component/ModalComponent";
import FormBecomeCustomer from "../form-become-customer/FormBecomeCustomer";

import {
  REQUEST_MODIFICATION,
  REQUEST_MODIFICATION_ADMIN,
} from "../../utils/Routes";
import { SANDBOX, PRE, LIVE } from "../../utils/GlobalConsts";
import {
  WITHOUT,
  PENDING,
  UNSUBCRIPTION,
  SUBSCRIBED,
} from "../../utils/GlobalConsts";

import {
  subscribeApp,
  cancelSub,
  modifySubscription,
} from "../../../services/ApiSubscriptions";

/* Sub-components */
import Section from "./components/Section";
import NotifierSection from "./components/NotifierSection";
import APISubscriptionSection from "./components/APISubscriptionSection";
import CredentialsSection from "./components/CredentialsSection";
import PendingRequestSection from "./components/PendingRequestSection";

// PLACEHOLDERS PARA INPUTS TEXT Y TEXTAREAS
const placeholders = {
  name: "Type name of APP",
  publicKey: "Insert your public Key",
  keyId: "Insert your Key ID",
  insertIssuer: "Insert your Key ID",
};

const RequestModificationTabContent = ({
  subscriptionAPIResponse,
  currentEnv,
  userClient,
  appName,
  organization,
}) => {
  const navigate = useNavigate();

  const {
    accessToken,
    userEmail,
    userData,
    nameOrganization,
    role,
    becomeCustomerSended,
  } = useGlobalVar();

  let [currentAPIState, setCurrentAPIState] = useState({});
  // OBJETO QUE FILTRA LOS DATOS SEGUN ENTORNO ESCOGIDO
  const [filteredData, setFilteredData] = useState(null);
  // OBJETO QUE RECOGE LOS DATOS
  const [formData, setFormData] = useState({
    nameOrganization: organization ? organization : nameOrganization,
    name: appName,
    environment: SANDBOX, //SANDBOX, PRE, LIVE
    subscribeAPI: {
      apiIso: WITHOUT, //WITHOUT, PENDING //
      apiRTran: WITHOUT,
      apiCustomer: WITHOUT,
    },
    publicKey: "",
    keyId: "",
    insertIssuer: "",
    statusReport: false,
    webhoot: false,
    nameSecurity: "",
    basicSecurity: false,
    basicSecurityParam: {
      user: "",
      pass: "",
      notificationUrl: "",
    },
    oauthSecurity: false,
    oauthParam: {
      clientSecret: "",
      clientID: "",
      notificationUrl: "",
      tokenUrl: "",
    },
    notSecurity: false,
    notSecurityParam: {
      tokenUrl: "",
    },
  });
  const [initialSubscribeApi, setInitialSubscribeApi] = useState(
    formData.subscribeAPI
  );
  // ESTADO DE EDICIÓN
  const [edition, setEdition] = useState(false);
  // ESTADO PENDIENTE EN UNA API
  const [isPending, setIsPending] = useState(false);
  const [showBecomeCustomerModal, setShowBecomeCustomerModal] = useState(false);

  const showPendingError = () => {
    showError("Request Upgrade plan already sended");
  };

  // EVENTO QUE RECOGE LOS DATOS Y LOS ASIGNA A FORMDATA
  const handleChange = (e) => {
    if (
      e.target.name === "notifications" ||
      e.target.name === "notifications_sec"
    ) {
      const selectedNotification = e.target.value;

      switch (selectedNotification) {
        case "statusReport":
          setFormData({
            ...formData,
            statusReport: true,
            webhoot: false,
          });
          break;
        case "webhoot":
          setFormData({
            ...formData,
            statusReport: false,
            webhoot: true,
          });
          break;
        case "notSecurity":
          setFormData({
            ...formData,
            notSecurity: true,
            oauthSecurity: false,
            basicSecurity: false,
            oauthParam: {
              clientSecret: "",
              clientID: "",
              notificationUrl: "",
              tokenUrl: "",
            },
            basicSecurityParam: {
              notificationUrl: "",
              pass: "",
              user: "",
            },
          });
          break;
        case "basicSecurity":
          setFormData({
            ...formData,
            notSecurity: false,
            oauthSecurity: false,
            basicSecurity: true,
            oauthParam: {
              clientSecret: "",
              clientID: "",
              notificationUrl: "",
              tokenUrl: "",
            },
            notSecurityParam: {
              tokenUrl: "",
            },
          });
          break;
        case "oauthSecurity":
          setFormData({
            ...formData,
            notSecurity: false,
            oauthSecurity: true,
            basicSecurity: false,
            notSecurityParam: {
              tokenUrl: "",
            },
            basicSecurityParam: {
              notificationUrl: "",
              pass: "",
              user: "",
            },
          });
          break;
        default:
          break;
      }
    } else {
      if (e.target.type === "checkbox") {
        const { name, checked } = e.target;

        let unactiveState;

        if (currentAPIState !== undefined) {
          unactiveState =
            currentAPIState[name] === UNSUBCRIPTION ||
            currentAPIState[name] === SUBSCRIBED
              ? UNSUBCRIPTION
              : WITHOUT;
        } else {
          unactiveState = WITHOUT;
        }

        let checkedStatus = checked ? SUBSCRIBED : unactiveState;

        setFormData({
          ...formData,
          subscribeAPI: {
            ...formData.subscribeAPI,
            [name]: checkedStatus,
          },
        });
      } else {
        const { name, value } = e.target;
        setFormData((prevFormData) => {
          const keys = name.split(".");
          let newFormData = { ...prevFormData };
          let nested = newFormData;

          for (let i = 0; i < keys.length - 1; i++) {
            nested = nested[keys[i]];
          }

          nested[keys[keys.length - 1]] = value;

          return newFormData;
        });
      }
    }
  };

  //ACTIVAMOS EDICIÓN
  const activeEdition = () => setEdition(true);

  // CANCELAMOS LA REQUEST
  const handleCancel = async () => {
    const data = {
      name: appName,
      environment: currentEnv,
      nameOrganization: nameOrganization,
    };

    // Verificar si filteredData.clientAppId y filteredData.secret no son undefined
    if (
      filteredData?.clientAppId !== undefined &&
      filteredData?.secret !== undefined
    ) {
      data.type = "MODIFY";
    }

    try {
      await cancelSub(data, accessToken, userData.email);

      localStorage.setItem("reloadCancel", "true");

      window.location.reload();
    } catch (error) {
      showError("There was a problem, please resubmit your request");
      console.error("Error deleting subscription:", error);
    }
  };

  const determineApiValue = (initialValue, formValue) => {
    if (
      initialValue === "WITHOUT" &&
      (formValue === "PENDING" || formValue === "SUBSCRIBED")
    ) {
      return "SUBSCRIPTION_REQUESTED";
    } else if (initialValue === "SUBSCRIBED" && formValue === "UNSUBCRIPTION") {
      return "UNSUBSCRIPTION_REQUESTED";
    } else if (initialValue === "UNSUBCRIPTION" && formValue === "SUBSCRIBED") {
      return "SUBSCRIPTION_REQUESTED";
    }
    // Puedes agregar más combinaciones aquí según las reglas que necesites
    return formValue; // Valor por defecto si no se cumple ninguna condición
  };

  // ENVIAMOS LOS DATOS DE LA MODIFICACION
  const handleModify = async (e) => {
    e.preventDefault();

    const apiPayload = {
      apiIso: determineApiValue(
        initialSubscribeApi.apiIso,
        formData.subscribeAPI.apiIso
      ),
      apiRTran: determineApiValue(
        initialSubscribeApi.apiRTran,
        formData.subscribeAPI.apiRTran
      ),
      apiCustomer: determineApiValue(
        initialSubscribeApi.apiCustomer,
        formData.subscribeAPI.apiCustomer
      ),
    };

    const payload = {
      nameOrganization: formData.nameOrganization,
      name: formData.name,
      environment: currentEnv,
      subscribeAPI: apiPayload,
      publicKey:
        formData.environment !== SANDBOX ? formData.publicKey : undefined,
      keyId: formData.environment !== SANDBOX ? formData.keyId : undefined,
      insertIssuer:
        formData.environment !== SANDBOX ? formData.insertIssuer : undefined,
      statusReport:
        formData.environment === LIVE ? formData.statusReport : undefined,
      webhoot: formData.environment === LIVE ? formData.webhoot : undefined,
      nameSecurity:
        formData.environment === LIVE ? formData.nameSecurity : undefined,
      basicSecurity:
        formData.environment === LIVE
          ? formData.basicSecurity
            ? formData.basicSecurity
            : undefined
          : undefined,
      basicSecurityParam:
        formData.environment === LIVE
          ? formData.basicSecurity
            ? formData.basicSecurityParam
            : undefined
          : undefined,
      oauthSecurity:
        formData.environment === LIVE
          ? formData.oauthSecurity
            ? formData.oauthSecurity
            : undefined
          : undefined,
      oauthParam:
        formData.environment === LIVE
          ? formData.oauthSecurity
            ? formData.oauthParam
            : undefined
          : undefined,
      notSecurity:
        formData.environment === LIVE
          ? formData.notSecurity
            ? formData.notSecurity
            : undefined
          : undefined,
      notSecurityParam:
        formData.environment === LIVE
          ? formData.notSecurity
            ? formData.notSecurityParam
            : undefined
          : undefined,
      clientAppId:
        formData.environment !== SANDBOX
          ? filteredData?.clientAppId
          : undefined,
      secret:
        formData.environment !== SANDBOX ? filteredData?.secret : undefined,
    };

    const cleanedPayload = JSON.parse(JSON.stringify(payload));

    try {
      // Llamar a la función de la API que realiza la solicitud de modificación
      await modifySubscription(cleanedPayload, userData.email, accessToken);

      // Si la solicitud es exitosa, manejar la redirección y otros efectos
      navigate(
        role === "DEVELOPER" || role === "ADMIN"
          ? REQUEST_MODIFICATION
          : REQUEST_MODIFICATION_ADMIN,
        {
          state: {
            env: cleanedPayload.environment,
            appName: cleanedPayload.name,
            organization: cleanedPayload.nameOrganization,
          },
        }
      );

      if (role === "DEVELOPER" || role === "ADMIN") {
        localStorage.setItem("reloadModify", "true");
      }

      window.scrollTo(0, 0);
      window.location.reload();
    } catch (error) {
      // Manejo de errores si la solicitud falla
      showError("There was a problem, please resubmit your request");
      console.error("Error modifying subscription:", error);
    }
  };

  // ENVIAR LOS DATOS DE NUEVA SUSCRIPCIÓN A ENDPOINT
  const handleSubmit = (e) => {
    e.preventDefault();

    // TODAS LAS APIS SELECCIONADAS SI ES SANDBOX
    const apiSandbox = {
      apiIso: PENDING,
      apiRTran: PENDING,
      apiCustomer: PENDING,
    };

    const apis = {
      apiIso: formData.subscribeAPI.apiIso === SUBSCRIBED ? PENDING : WITHOUT,
      apiRTran:
        formData.subscribeAPI.apiRTran === SUBSCRIBED ? PENDING : WITHOUT,
      apiCustomer:
        formData.subscribeAPI.apiCustomer === SUBSCRIBED ? PENDING : WITHOUT,
    };

    const payload = {
      nameOrganization: formData.nameOrganization,
      name: formData.name,
      environment: currentEnv,
      subscribeAPI: currentEnv === SANDBOX ? apiSandbox : apis,
      publicKey:
        formData.environment !== SANDBOX ? formData.publicKey : undefined,
      keyId: formData.environment !== SANDBOX ? formData.keyId : undefined,
      insertIssuer:
        formData.environment !== SANDBOX ? formData.insertIssuer : undefined,
      statusReport:
        formData.environment === LIVE ? formData.statusReport : undefined,
      webhoot: formData.environment === LIVE ? formData.webhoot : undefined,
      nameSecurity:
        formData.environment === LIVE ? formData.nameSecurity : undefined,
      basicSecurity:
        formData.environment === LIVE
          ? formData.basicSecurity
            ? formData.basicSecurity
            : undefined
          : undefined,
      basicSecurityParam:
        formData.environment === LIVE
          ? formData.basicSecurity
            ? formData.basicSecurityParam
            : undefined
          : undefined,
      oauthSecurity:
        formData.environment === LIVE
          ? formData.oauthSecurity
            ? formData.oauthSecurity
            : undefined
          : undefined,
      oauthParam:
        formData.environment === LIVE
          ? formData.oauthSecurity
            ? formData.oauthParam
            : undefined
          : undefined,
      notSecurity:
        formData.environment === LIVE
          ? formData.notSecurity
            ? formData.notSecurity
            : undefined
          : undefined,
      notSecurityParam:
        formData.environment === LIVE
          ? formData.notSecurity
            ? formData.notSecurityParam
            : undefined
          : undefined,
    };

    const cleanedPayload = JSON.parse(JSON.stringify(payload));

    subscribeApp(cleanedPayload, accessToken, userData.email)
      .then((response) => {
        navigate(
          role === "DEVELOPER" || role === "ADMIN"
            ? REQUEST_MODIFICATION
            : REQUEST_MODIFICATION_ADMIN,
          {
            state: {
              env: cleanedPayload.environment,
              appName: cleanedPayload.name,
              organization: cleanedPayload.nameOrganization,
            },
          }
        );

        if (role === "DEVELOPER" || role === "ADMIN")
          localStorage.setItem("reload", "true");

        window.scrollTo(0, 0);
        window.location.reload();
      })
      .catch((error) => {
        showError("Check empty form fields");
        console.error("Hubo un error al enviar el formulario:", error);
      });
  };

  // RESETEAMOS EL FORMULARIO CON LOS DATOS DE filteredData
  const fillFormData = useCallback(() => {
    setCurrentAPIState((prevState) => {
      // Solo actualizar si el estado ha cambiado
      if (prevState !== filteredData?.subscribeAPI) {
        return filteredData?.subscribeAPI;
      }
      return prevState;
    });

    setFormData((prevFormData) => {
      // Solo actualizar si los datos han cambiado para evitar bucles
      const updatedFormData = {
        ...prevFormData,
        environment: currentEnv,
        subscribeAPI: {
          ...prevFormData.subscribeAPI,
          apiIso: filteredData?.subscribeAPI?.apiIso || WITHOUT,
          apiRTran: filteredData?.subscribeAPI?.apiRTran || WITHOUT,
          apiCustomer: filteredData?.subscribeAPI?.apiCustomer || WITHOUT,
        },
        publicKey: filteredData?.publicKey || "",
        keyId: filteredData?.keyId || "",
        insertIssuer: filteredData?.insertIssuer || "",
        statusReport: filteredData?.statusReport || false,
        webhoot: filteredData?.webhoot || false,
        nameSecurity: filteredData?.nameSecurity || "",
        basicSecurity: filteredData?.basicSecurity || false,
        basicSecurityParam: {
          ...prevFormData.basicSecurityParam,
          user: filteredData?.basicSecurityParam?.user || "",
          pass: filteredData?.basicSecurityParam?.pass || "",
          notificationUrl:
            filteredData?.basicSecurityParam?.notificationUrl || "",
        },
        oauthSecurity: filteredData?.oauthSecurity || false,
        oauthParam: {
          ...prevFormData.oauthParam,
          clientSecret: filteredData?.oauthParam?.clientSecret || "",
          clientID: filteredData?.oauthParam?.clientID || "",
          notificationUrl: filteredData?.oauthParam?.notificationUrl || "",
          tokenUrl: filteredData?.oauthParam?.tokenUrl || "",
        },
        notSecurity: filteredData?.notSecurity || false,
        notSecurityParam: {
          ...prevFormData.notSecurityParam,
          tokenUrl: filteredData?.notSecurityParam?.tokenUrl || "",
        },
      };

      // Solo actualiza el formData si es diferente al actual
      if (JSON.stringify(prevFormData) !== JSON.stringify(updatedFormData)) {
        return updatedFormData;
      }
      return prevFormData;
    });

    if (filteredData == null) {
      setEdition(true);
    } else {
      setEdition(false);
    }
  }, [filteredData, currentEnv]);

  // SI EL USUARIO NO ES CLIENTE Y ESTÁ EN LA PESTAÑA PRE O LIVE NECESITA UPGRADEAR SU PLAN
  const needUpgrade =
    !userClient && (currentEnv === PRE || currentEnv === LIVE);

  // FILTRAMOS LOS DATOS SEGÚN LA PESTAÑA (ENTORNO) ESCOGIDO POR EL USUARIO
  useEffect(() => {
    const filtered = subscriptionAPIResponse.subscriptionStateList.find(
      (item) => item.environment === currentEnv
    );

    setFilteredData(filtered ? filtered : null);

    fillFormData();
  }, [subscriptionAPIResponse, currentEnv, fillFormData]);

  // ACTUALIZAMOS FORMDATA CON LOS VALORES DEL ENTORNO ACTUAL (filteredData)
  useEffect(() => {
    fillFormData();

    setIsPending(
      filteredData
        ? Object.values(filteredData?.subscribeAPI).some(
            (item) => item === PENDING
          )
        : false
    );

    setInitialSubscribeApi(filteredData?.subscribeAPI);
  }, [filteredData, fillFormData]);

  useEffect(() => {
    const reloaded = localStorage.getItem("reload");
    const reloadedModify = localStorage.getItem("reloadModify");
    const reloadedCancel = localStorage.getItem("reloadCancel");
    if (reloaded) {
      showSuccess(`Your subscription in ${currentEnv} has been registered`);
      localStorage.removeItem("reload");
    } else if (reloadedModify) {
      showSuccess(`Your modify request has been registered`);
      localStorage.removeItem("reloadModify");
    } else if (reloadedCancel) {
      showSuccess(`Request canceled`);
      localStorage.removeItem("reloadCancel");
    }
  }, [currentEnv]);

  return (
    <>
      <div
        className={`request-modification-tab-content ${
          !edition ? "not-editable" : ""
        }`.trim()}
      >
        <div className="request-modification-tab-content__sections">
          {needUpgrade ? (
            <Section className="request-modification-tab-content__sections__section--user-no-customer">
              <Section.Title>Contact our team</Section.Title>
              <Section.Description>
                To use this environment you need to contact our sales team.
              </Section.Description>
              <div className="request-modification-tab-content__sections__section__button">
                <>
                  {becomeCustomerSended ? (
                    <div onClick={() => showPendingError()}>
                      <LinkComponent
                        text={"Upgrade pending"}
                        icon={"icon-pending"}
                        bgColor={"bg-pending"}
                      />
                    </div>
                  ) : (
                    <div onClick={() => setShowBecomeCustomerModal(true)}>
                      <LinkComponent
                        text={"Upgrade your plan"}
                        icon={"icon-spark"}
                        filled
                      />
                    </div>
                  )}
                </>
              </div>
            </Section>
          ) : (
            <>
              {
                // REQUEST IN PROGRESS
                isPending && (
                  <>
                    <PendingRequestSection handleCancel={handleCancel} />
                    <DividerComponent />
                  </>
                )
              }

              {/* CREDENCIALES PRIVADAS DE PAGONXT */}
              <CredentialsSection
                currentEnv={currentEnv}
                filteredData={filteredData}
                isPending={isPending}
              />

              <DividerComponent />

              <form onSubmit={handleSubmit}>
                <APISubscriptionSection
                  formData={formData}
                  handleChange={handleChange}
                  currentEnv={currentEnv}
                  placeholders={placeholders}
                />

                {currentEnv === LIVE && (
                  <>
                    <DividerComponent />
                    <NotifierSection
                      formData={formData}
                      handleChange={handleChange}
                    />
                  </>
                )}

                <div className="request-modification-tab-content__sections__submit-buttons">
                  {!edition && !isPending && currentEnv !== SANDBOX && (
                    <>
                      <DividerComponent />
                      <div className="request-subscription__form__submit-buttons__cancel">
                        <ButtonComponent
                          text={"Edit"}
                          color={"button-component--neutral"}
                          onClick={activeEdition}
                        />
                      </div>
                    </>
                  )}
                  {edition && filteredData != null && (
                    <>
                      <DividerComponent />
                      <div className="request-modification-tab-content__sections__submit-buttons__content">
                        <div className="request-subscription__form__submit-buttons__cancel">
                          <ButtonComponent
                            text={"Cancel"}
                            color={"button-component--neutral"}
                            onClick={() => fillFormData()}
                          />
                        </div>
                        <div className="request-subscription__form__submit-buttons__confirm">
                          <ButtonComponent
                            onClick={handleModify}
                            text={"Request Modification"}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {filteredData === null && (
                    <>
                      <DividerComponent />
                      <div className="request-subscription__form__submit-buttons__cancel">
                        <ButtonComponent
                          text={"Subscription API"}
                          onClick={handleSubmit}
                        />
                      </div>
                    </>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </div>

      <ModalComponent
        show={showBecomeCustomerModal}
        onClose={() => setShowBecomeCustomerModal(false)}
        size={"xl"}
      >
        <FormBecomeCustomer onClose={() => setShowBecomeCustomerModal(false)} />
      </ModalComponent>
    </>
  );
};

export default RequestModificationTabContent;
