import { CognitoUserPool} from "amazon-cognito-identity-js";
//DEV
/* export const poolData = {
    UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID_DEV , 
    ClientId: process.env.REACT_APP_AWS_CLIENT_ID_DEV
 }; */

// PRE
/* export const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID_PRO,
    ClientId: process.env.REACT_APP_AWS_CLIENT_ID_PRO 
}; */

// PRO
const poolData = {
   UserPoolId: process.env.REACT_APP_USER_POOL_ID,
   ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
}; 

export default new CognitoUserPool(poolData)